<div (click)="openProductInfoModal()" class="configurator__product">
  <img
    [alt]="product.title"
    [ngStyle]="{
      'width': productWidth + 'px',
      'height': productHeight + 'px',
      'margin-bottom': marginBottom + 'px',
    }"
    [ngClass]="{
      'configurator__product--black': product.color.value === Color.BLACK.value,
      'configurator__product--white': product.color.value === Color.WHITE.value
    }"
    [src]="productImg"
    onerror="this.src='assets/images/fallback.png'"
  >
  <button
    *ngIf="product.accessories && product.accessories.length > 0"
    class="button button--outline configurator__product__accessory-icon"
    [tooltip]="'configurator.configurator-product.accessories-tooltip' | translate"
  >
    <span>Accessoires</span>
    <span class="button__info">{{getNumberOfAccessories()}}</span>
  </button>

  <div class="configurator__product__edit-icons">
    <button
      (click)="$event.stopPropagation(); onDeleteClick()"
      *ngIf="isTopLevel"
      [tooltip]="'configurator.configurator-product.remove-tooltip' | translate"
      class="button button--icon button--outline button--red ">
      <span class="material-symbols-outlined">
        delete
      </span>
    </button>

    <button
      (click)="$event.stopPropagation(); openSettingsModal()"
      *ngIf="product.isHangingCloset"
      [tooltip]="'configurator.configurator-product.settings-tooltip' | translate"
      class="button button--icon button--outline">
      <span class="material-symbols-outlined">settings</span>
    </button>
  </div>
</div>

<modal [id]="productInfoModalId" modalClass="modal--wide modal--scroll">
  <app-product-info-modal
    #productInfoModalComponent
    [modalId]="productInfoModalId"
    [product]="product"
  >
  </app-product-info-modal>
</modal>

<modal [id]="productSettingsModalId">
  <app-product-settings-modal
    [modalId]="productSettingsModalId"
    [product]="product"
  >
  </app-product-settings-modal>
</modal>
