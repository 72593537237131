<div class="startscreen">
  <div class="startscreen__header">
    <div class="startscreen__text">
      <h1 class="startscreen__title">{{ 'configurator.start-screen.title' | translate }}</h1>

      <p>{{'configurator.start-screen.text' | translate}}</p>
    </div>
    <a class="button button--accent button--text-icon whitespace-nowrap" routerLink="/configurator">
      {{ 'configurator.start-screen.button' | translate }}
      <span class="material-symbols-outlined">
        navigate_next
      </span>
    </a>
  </div>
  <div class="startscreen__content">
    <div class="video">
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        frameborder="0"
        src="https://www.youtube.com/embed/uJSmFxZnxEg"
        title="Wasombouw - tutorial">
      </iframe>
    </div>
  </div>
  <app-translation-button class="translation-button"></app-translation-button>

</div>



<div class="configurator__background"></div>
