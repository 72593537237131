<div
  class="configurator__product-stack h-full"
  [ngClass]="{'configurator__product-stack--bottom': isBottomStack}"
>
  <div *ngIf="isBottomStack && !(isFirstStack && isLastStack)"
       class="configurator__measurement-holder configurator__measurement-holder--horizontal">
    <p>{{ stackWidth }}cm</p>
    <div [ngStyle]="{'width': stackWidth * multiplyFactor + 'px'}" class="line"></div>
  </div>

  <ng-container *ngFor="let level of productStack.productLevels; let levelId = index">
    <ng-container *ngIf="level.product && !level.productStacks; else stackWithMultipleProducts">
      <div (click)="onAddProduct()" *ngIf="levelId === 0 && level.product.title.toLowerCase().includes('onderkast')"
           class="configurator__add-button configurator__add-button--no-padding">
        <button
          [position]="TooltipPosition.RIGHT"
          [tooltip]="'configurator.configurator.add-tooltip' | translate"
          class="button button--icon button--accent">
          <span class="material-symbols-outlined">
            add
          </span>
        </button>
      </div>
      <app-configurator-product
        class="h-full flex items-end"
        [isTopLevel]="levelId === 0"
        [level]="level"
        [product]="level.product"
        [stack]="productStack"
        [totalStackHeightWithoutHangingProducts]="totalStackHeightWithoutHangingProducts"
      >
      </app-configurator-product>
    </ng-container>

    <ng-template #stackWithMultipleProducts>
      <div class="configurator__different-width">
        <ng-container *ngFor="let stack of level.productStacks">
          <ng-container *ngIf="stack; else empty">
            <div class="flex items-end">
              <app-configurator-product-stack
                [productStack]="stack"
                [totalStackHeightWithoutHangingProducts]="totalStackHeightWithoutHangingProducts">
              </app-configurator-product-stack>
            </div>
          </ng-container>
          <ng-template #empty>
            <div
              (click)="onAddProduct(level)"
              class="configurator__add-button configurator__add-button--empty"
            >
              <button
                *ngIf="placeForExtraProduct"
                [position]="TooltipPosition.RIGHT"
                [tooltip]="'configurator.configurator.add-tooltip' | translate"
                class="button button--icon button--accent">
                <span class="material-symbols-outlined">
                  add
                </span>
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isBottomStack">
    <div class="bottom-stack__icons bottom-stack__icons--desktop">
      <button
        (click)="onPositionClick(PositionSwitchDirections.LEFT)"
        [disabled]="isFirstStack"
        [tooltip]="'configurator.configurator.move-tooltip' | translate" [zIndex]="4"
        class="button button--icon button--outline">
      <span class="material-symbols-outlined">
        navigate_before
      </span>
      </button>
      <button
        (click)="duplicateProductStack()"
        [tooltip]="'configurator.configurator.duplicate-tooltip' | translate" [zIndex]="4"
        class="button button--icon button--outline">
      <span class="material-symbols-outlined">
        content_copy
      </span>
      </button>
      <button
        (click)="onPositionClick(PositionSwitchDirections.RIGHT)"
        [disabled]="isLastStack"
        [tooltip]="'configurator.configurator.move-tooltip' | translate" [zIndex]="4"
        class="button button--icon button--outline">
      <span class="material-symbols-outlined">
        navigate_next
      </span>
      </button>
    </div>

    <div #iconsMobile class="bottom-stack__icons bottom-stack__icons--mobile">
      <button (click)="openMenu()" class="button button--icon button--outline">
          <span class="material-symbols-outlined">
            more_vert
          </span>
      </button>

      <div *ngIf="moreOpened" class="bottom-stack__more">
        <button
          (click)="onPositionClick(PositionSwitchDirections.LEFT)"
          [disabled]="isFirstStack"
          class="button button--icon-text button--outline">
      <span class="material-symbols-outlined">
        navigate_before
      </span>
          {{ 'configurator.configurator.move-tooltip' | translate }}
        </button>
        <button
          (click)="duplicateProductStack()"
          class="button button--icon-text button--outline">
      <span class="material-symbols-outlined">
        content_copy
      </span>
          {{ 'configurator.configurator.duplicate-tooltip' | translate }}
        </button>
        <button
          (click)="onPositionClick(PositionSwitchDirections.RIGHT)"
          [disabled]="isLastStack"
          class="button button--icon-text button--outline">
      <span class="material-symbols-outlined">
        navigate_next
      </span>
          {{ 'configurator.configurator.move-tooltip' | translate }}
        </button>
      </div>
    </div>

  </ng-container>
</div>

<modal (isOpening)="addProductModalOpen = true" (isClosing)="addProductModalOpen = false"
       [id]="'configuratorProductStack-addProduct-' + productStack.id" modalClass="modal--giant">
  <app-add-product-modal
    [isOpen]="addProductModalOpen"
    #addProductModalComponent
    [level]="selectedLevel"
    [modalId]="'configuratorProductStack-addProduct-' + productStack.id"
    [stack]="productStack"
    [totalStackHeightWithoutHangingProducts]="totalStackHeightWithoutHangingProducts"
  ></app-add-product-modal>
</modal>
