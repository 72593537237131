<ng-container *ngIf="configuration$ | async as configuration">
  <div class="configurator__container">
    <ng-container *ngIf="configuration.selectedWallProperty.image !== 'default'; else defaultWall">
      <div class="configurator__wall configurator__wall--img">
        <div [ngStyle]="{'background-image': 'url(' + configuration.selectedWallProperty.image +')'}"></div>
      </div>
    </ng-container>
    <ng-template #defaultWall>
      <div class="configurator__background"></div>
    </ng-template>

    <ng-container
      *ngIf="configuration && configuration.productStacks && configuration.productStacks.length > 0; else emptyConfiguration">
      <div
        [ngClass]="{'configurator--small-icons': multiplyFactor < 1.5, 'configurator--mini-icons': multiplyFactor < .5}"
        class="configurator">

        <div class="configurator__measurement-holder configurator__measurement-holder--vertical">
          <div class="line__container">
            <div>
              <p>{{ totalHeight }}cm</p>
              <p
                *ngIf="configuration?.roomDimensions?.height"
                [ngClass]="{'text--warning': configuration?.roomDimensions?.height < totalHeight}"
                class="text--grey line__warning"
              >
                <span *ngIf="configuration?.roomDimensions?.height < totalHeight; else noWarningHeight"
                      [tooltip]="'configurator.configurator.roomDimensionsToHigh' | translate">
                  <span class="material-symbols-outlined">
                    warning
                  </span>
                  max {{ configuration.roomDimensions.height }}cm
                </span>
                <ng-template #noWarningHeight>
                  <span [tooltip]="'configurator.configurator.roomDimensionsHeight' | translate">
                    max {{ configuration.roomDimensions.height }}cm
                  </span>
                </ng-template>
              </p>
            </div>
            <div [ngStyle]="{'height': totalHeight * multiplyFactor + 'px'}" class="line"></div>
          </div>
        </div>

        <div class="configurator__hover-container">
          <div (click)="onAddProduct(Side.LEFT)" [ngStyle]="{'height': totalHeight * multiplyFactor + 'px'}"
               class="configurator__add-button">
            <button
              [position]="TooltipPosition.LEFT"
              [tooltip]="'configurator.configurator.add-tooltip' | translate"
              class="button button--accent button--icon"
              id="addButton-left">
            <span class="material-symbols-outlined">
              add
            </span>
            </button>
          </div>
          <div class="configurator__center">
            <div class="configurator__measurement-holder configurator__measurement-holder--horizontal">
              <div class="text-right">
                <p class="!mb-0">{{ totalWidth }}cm</p>
                <p
                  *ngIf="configuration?.roomDimensions?.width"
                  [ngClass]="{'text--warning': configuration?.roomDimensions?.width < totalWidth}"
                  class="text--grey line__warning"
                >
                  <span *ngIf="configuration?.roomDimensions?.width < totalWidth; else noWarningWidth"
                        [tooltip]="'configurator.configurator.roomDimensionsToWide' | translate">
                    <span *ngIf="configuration?.roomDimensions?.height < totalHeight" class="material-symbols-outlined">
                      warning
                    </span>
                    max {{ configuration.roomDimensions.width }}cm
                  </span>
                  <ng-template #noWarningWidth>
                    <span [tooltip]="'configurator.configurator.roomDimensionsWidth' | translate">
                      max {{ configuration.roomDimensions.width }}cm
                    </span>
                  </ng-template>
                </p>
              </div>
              <div [ngStyle]="{'width': totalWidth * multiplyFactor + 'px'}" class="line"></div>
            </div>
            <div class="configurator__products">
              <app-configurator-product-stack
                *ngFor="let productStack of configuration.productStacks; let i = index"
                [isBottomStack]="true"
                [isFirstStack]="i === 0"
                [isLastStack]="i === configuration.productStacks.length - 1"
                [productStack]="productStack"
                class="h-full"
              >
              </app-configurator-product-stack>

              <div class="configurator__shadow">
                <svg fill="none" viewBox="0 0 353 100" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path d="M25 25.7744L235.5 25.7744L328 74.5H162.5L25 25.7744Z"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div (click)="onAddProduct(Side.RIGHT)" [ngStyle]="{'height': totalHeight * multiplyFactor + 'px'}"
               class="configurator__add-button">
            <button
              [position]="TooltipPosition.RIGHT"
              [tooltip]="'configurator.configurator.add-tooltip' | translate"
              class="button button--icon button--accent"
              id="addButton-right">
            <span class="material-symbols-outlined">
              add
            </span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <app-product-list></app-product-list>

    <app-translation-button class="translation-button"></app-translation-button>

    <div class="configurator__buttons">
      <button
        (click)="openUpdateBackgroundModal()"
        class="button button--outline button--icon-text">
        <span class="material-symbols-outlined">
          room_preferences
        </span>
        {{ 'configurator.configurator.room-preferences' | translate }}
      </button>

      <!--<div class="configurator__buttons&#45;&#45;colors">
        <ng-container *ngIf="productProperties?.colors?.length > 0">

          <label
            *ngFor="let colorProperty of productProperties?.colors"
            [tooltip]="'Verander de kleur van alle producten naar ' + colorProperty.name.toLowerCase()" [zIndex]="4"
            class="configurator__color-chooser configurator__color-chooser&#45;&#45;black"
            [for]="colorProperty.id">
            <input (change)="updateColor(colorProperty.id)" [(ngModel)]="selectedColor" [value]="Color.BLACK"
                   [id]="colorProperty.id"
                   name="color" type="radio">
            <div class="color__container">
              <div
                [style]="{'background': colorProperty.image ? 'url('+colorProperty.image+')' : colorProperty.hex}"></div>
              <div class="color__check">
            <span class="material-symbols-outlined">
              check
            </span>
              </div>
            </div>
          </label>

        </ng-container>
        &lt;!&ndash;<label
          [tooltip]="'Verander de kleur van alle producten naar zwart'" [zIndex]="4"
          class="configurator__color-chooser configurator__color-chooser&#45;&#45;black"
          for="black">
          <input (change)="updateColor(Color.BLACK)" [(ngModel)]="selectedColor" [value]="Color.BLACK" id="black"
                 name="color" type="radio">
          <div class="color__container">
            <div></div>
            <div class="color__check">
            <span class="material-symbols-outlined">
              check
            </span>
            </div>
          </div>
        </label>
        <label
          [tooltip]="'Verander de kleur van alle producten naar wit'" [zIndex]="4"
          class="configurator__color-chooser configurator__color-chooser&#45;&#45;white"
          for="white">
          <input (change)="updateColor(Color.WHITE)" [(ngModel)]="selectedColor" [value]="Color.WHITE" id="white"
                 name="color" type="radio">
          <div class="color__container">
            <div></div>
            <div class="color__check">
              <span class="material-symbols-outlined">
                check
              </span>
            </div>
          </div>
        </label>&ndash;&gt;
        <input [(ngModel)]="selectedColor" [value]="undefined" class="hidden" id="undefined" name="color" type="radio">
      </div>-->
    </div>

    <ng-container *ngIf="configuration.selectedFloorProperty.image !== 'default'; else defaultBackground">
      <div class="configurator__background configurator__background--img">
        <div [ngStyle]="{'background-image': 'url(' + configuration.selectedFloorProperty.image +')'}"></div>
      </div>
    </ng-container>
    <ng-template #defaultBackground>
      <div class="configurator__background"></div>
    </ng-template>

  </div>

  <ng-template #emptyConfiguration>
    <div (click)="onAddProduct()"
         class="configurator__add-button configurator__add-button--visible configurator__add-button--no-padding">
      <button
        class="button button--icon-text button--accent"
        id="addButton-start">
        <span class="material-symbols-outlined">
          add
        </span>
        {{ 'configurator.configurator.add' | translate }}
      </button>
    </div>
  </ng-template>

  <modal (isClosing)="addProductModalOpen = false" (isOpening)="addProductModalOpen = true" id="configurator-addProduct"
         modalClass="modal--giant">
    <app-add-product-modal
      #addProductModalComponentConfigurator
      [isOpen]="addProductModalOpen"
      [side]="selectedSide"
      modalId="configurator-addProduct">
    </app-add-product-modal>
  </modal>

  <modal id="update-background" modalClass="modal--wide">
    <app-edit-room-modal
      [configuration]="configuration"
      modalId="update-background">
    </app-edit-room-modal>
  </modal>
</ng-container>



