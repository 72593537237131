<ng-container *ngIf="isOpen">


  <div class="modal__header">
    <div class="modal__header-text">
      <h2 class="modal__title">{{ "configurator.add-product-modal.title" | translate }}</h2>
    </div>
    <app-modal-close-button (onClose)="closeModal()"></app-modal-close-button>
  </div>
  <div class="modal__content">
    <div class="modal__filters">
      <search-bar class="w-full"
                  #searchBarComponent
                  [(ngModel)]="searchKeyword"
                  [placeholder]="'configurator.add-product-modal.search-placeholder' | translate">
      </search-bar>

      <!--<app-color-filter class="h-full" (onColorSelected)="updateSelectedColor($event)"></app-color-filter>-->
    </div>


    <ng-container *ngIf="!productsRequestFailed; else productsRequestFailedMessage">
      <ng-container *ngIf="!categoriesRequestFailed; else categoriesRequestFailedMessage">
        <ng-container *ngIf="(categories$ | async) && (products$ | async); else loading">


          <app-category-chooser></app-category-chooser>

          <ng-container
            *ngIf="products | filterProducts:searchKeyword:selectedColor:selectedCategory:stack as filteredProducts">
            <ng-container *ngIf="filteredProducts.length > 0; else noProductsMatch">
              <div *ngIf="showMessageWhenProductsAreFiltered()" class="text--grey flex items-center">
                <span class="material-symbols-outlined mr-2">info</span>
                <p>
                  {{ "configurator.add-product-modal.info" | translate }}
                </p>
              </div>
              <div class="product__grid">
                <app-product-card
                  (click)="onProductClick(product)"
                  *ngFor="let product of filteredProducts"
                  [product]="product"
                  class="product__grid-item"></app-product-card>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #noProductsMatch>
        <app-empty-results
          class="h-full"
          [message]="'configurator.add-product-modal.info' | translate">
        </app-empty-results>
      </ng-template>
    </ng-container>
  </div>

  <ng-template #loading>
    <loading-icon></loading-icon>
  </ng-template>

  <ng-template #productsRequestFailedMessage>
    <div class="products__reload-message">
      <p>
        {{ "configurator.add-product-modal.reload-products-message" | translate }}
      </p>
      <button (click)="reloadProducts()" class="button button--outline button--icon-text">
        <span class="material-symbols-outlined">refresh</span>
        {{ "configurator.add-product-modal.reload-action" | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #categoriesRequestFailedMessage>
    <div class="products__reload-message">
      <p>
        {{ "configurator.add-product-modal.reload-categories-message" | translate }}
      </p>
      <button (click)="reloadCategories()" class="button button--outline button--icon-text">
        <span class="material-symbols-outlined">refresh</span>
        {{ "configurator.add-product-modal.reload-action" | translate }}
      </button>
    </div>
  </ng-template>

  <modal [id]="productOptionsModalId">
    <app-product-options-modal
      (optionsSelected)="addProduct($event)"
      [modalId]="productOptionsModalId"
      [product]="selectedProduct"
      [sideNeeded]="sideNeeded"
    ></app-product-options-modal>
  </modal>
</ng-container>
