import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Product} from "../../../products/models/product";
import {Select, Store} from "@ngxs/store";
import {DeleteProductFromConfiguration} from "../../configurator.actions";
import {ConfiguratorState} from "../../configurator.state";
import {Observable, Subscription} from "rxjs";
import {v4 as uuid} from "uuid";
import {ModalService} from "../../../shared/modal/modal.service";
import {ProductLevel} from "../../models/product-level";
import {ProductStack} from "../../models/product-stack";
import {Configuration, getTotalHeightBelowProductInConfiguration} from "../../models/configuration";
import {ProductInfoModalComponent} from "../../../products/components/product-info-modal/product-info-modal.component";
import Color from "../../../products/models/colors";

@Component({
  selector: 'app-configurator-product',
  templateUrl: './configurator-product.component.html',
  styleUrls: ['./configurator-product.component.scss']
})
export class ConfiguratorProductComponent implements OnInit, OnDestroy {

  @Input() product: Product;
  @Input() stack: ProductStack;
  @Input() level: ProductLevel;
  @Input() isTopLevel: boolean;
  @Input() totalStackHeightWithoutHangingProducts: number;

  @Select(ConfiguratorState.multiplyFactor) multiplyFactor$: Observable<number>
  @Select(ConfiguratorState.configuration) configuration$: Observable<Configuration>
  multiplyFactor: number = 2;
  configuration: Configuration;

  @ViewChild('productInfoModalComponent') productInfoModalComponent: ProductInfoModalComponent;

  productInfoModalId: string;
  productSettingsModalId: string;

  productWidth: number;
  productHeight: number;
  marginBottom: number;

  productImg: string;

  private subscriptions = new Subscription();

  constructor(
    private store: Store,
    private modalService: ModalService,
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.fetchData();
    this.productInfoModalId = `productInfoModal-${uuid()}`
    this.productSettingsModalId = `productSettingsModal-${uuid()}`
    this.productImg = this.product.selectedImgUrl ? this.product.selectedImgUrl : (this.product.selectedVariation ? this.product.selectedVariation.imgUrl : this.product.imgUrl)
  }


  onDeleteClick(): void {
    this.store.dispatch(new DeleteProductFromConfiguration(this.stack.id, this.product.id, this.level.id))
  }

  openProductInfoModal() {
    this.modalService.open(this.productInfoModalId)
    this.productInfoModalComponent.focusOnSearchbar();
  }

  openSettingsModal() {
    this.modalService.open(this.productSettingsModalId)
  }

  getNumberOfAccessories(): number {
    if (!this.product.accessories) return 0;
    return this.product.accessories.reduce((total, accessory) => total + accessory.numberInConfiguration, 0)
  }

  calculateMarginBottom() {
    this.marginBottom = (this.product.hangingHeight - this.product.height - getTotalHeightBelowProductInConfiguration(this.configuration, this.product.configurationId)) * this.multiplyFactor
  }

  private fetchData() {
    this.subscriptions.add(
      this.multiplyFactor$.subscribe(factor => {
          this.multiplyFactor = factor;
          this.productWidth = this.product.width * this.multiplyFactor;
          this.productHeight = this.product.selectedVariation?.height ? this.product.selectedVariation.height * this.multiplyFactor : this.product.height * this.multiplyFactor;
          if (this.configuration) this.calculateMarginBottom()
        }
      )
    );
    this.subscriptions.add(
      this.configuration$.subscribe(configuration => {
          this.configuration = configuration;
          if (this.multiplyFactor) this.calculateMarginBottom();
        }
      )
    )
  }

  protected readonly Color = Color;
}
