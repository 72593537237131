import {Action, Selector, State, StateContext, StateToken} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ConfiguratorService} from "./configurator.service";
import {
  Configuration,
  getLevelFromConfiguration,
  getStackFromConfiguration,
  getTotalHeightBelowProductInConfiguration
} from "./models/configuration";
import {
  AddProductToConfiguration,
  ChangeColorOfProducts,
  DeleteProductFromConfiguration,
  DuplicateProductStack, FetchAllRoomProperties,
  ResetConfiguration,
  SwitchProductStackPosition, UpdateFloorProperty,
  UpdateFloorType,
  UpdateHangingHeightOfProduct,
  UpdateMultiplyFactor,
  UpdateNumberOfAccessoriesInProduct, UpdateRoomDimensions,
  UpdateSelectedCategory,
  UpdateTotalHeight, UpdateWallProperty
} from "./configurator.actions";
import {insertItem, patch} from "@ngxs/store/operators";
import {Side} from "./models/side";
import {getProductWithConfigurationIdFormStack, ProductStack} from "./models/product-stack";
import {ProductLevel} from "./models/product-level";
import {v4 as uuid} from "uuid";
import {Product} from "../products/models/product";
import Color from "../products/models/colors";
import {Category} from "../products/models/category";
import {RoomProperty} from "./models/room-property";
import {tap} from "rxjs";
import {ProductStateModel} from "../products/product.state";

const CONFIGURATOR_STATE_TOKEN = new StateToken<ConfiguratorStateModel>('configurator');

export interface ConfiguratorStateModel {
  configuration: Configuration,
  multiplyFactor: number,
  totalHeight: number,
  selectedCategory: Category,
  roomProperties: RoomProperty;
}

@State<ConfiguratorStateModel>({
  name: CONFIGURATOR_STATE_TOKEN,
  defaults: {
    configuration: new Configuration(),
    multiplyFactor: 2,
    totalHeight: 0,
    selectedCategory: null,
    roomProperties: null,
  }
})
@Injectable()
export class ConfiguratorState {
  constructor(private configuratorService: ConfiguratorService) {
  }

  @Selector()
  static configuration(state: ConfiguratorStateModel): Configuration {
    return state?.configuration;
  }

  @Selector()
  static multiplyFactor(state: ConfiguratorStateModel): number {
    return state?.multiplyFactor;
  }

  @Selector()
  static totalHeight(state: ConfiguratorStateModel): number {
    return state?.totalHeight;
  }

  @Selector()
  static selectedCategory(state: ConfiguratorStateModel): Category {
    return state?.selectedCategory;
  }

  @Selector()
  static roomProperties(state: ConfiguratorStateModel): RoomProperty {
    return state?.roomProperties;
  }

  @Action(AddProductToConfiguration)
  addProductToConfiguration(ctx: StateContext<ConfiguratorStateModel>, action: AddProductToConfiguration) {
    const configuration: Configuration = JSON.parse(JSON.stringify(ctx.getState().configuration));

    let newProduct: Product = {...action.product, configurationId: uuid()};

    newProduct.selectedAssemblySide = action.assembleSide
    if (action.imgUrl) newProduct.selectedImgUrl = action.imgUrl;
    if (newProduct.isHangingCloset) newProduct.hangingHeight = Math.max(ctx.getState().totalHeight, newProduct.height, action.hangingHeight)
    if (action.productVariation) newProduct = {...newProduct, selectedVariation: action.productVariation}

    if (action.levelId) {
      // Add product to an existing level, based on the position that is chosen by the user, an undefined element is added on the right place.
      const level = getLevelFromConfiguration(configuration, action.levelId);
      if (!level || !level.productStacks) return;

      let newStack: ProductStack;
      if (action.position === undefined) {
        newStack = new ProductStack(uuid(), [new ProductLevel(uuid(), newProduct)]);
        level.productStacks.push(newStack);
      } else {
        newStack = new ProductStack(
          uuid(),
          [new ProductLevel(uuid(), newProduct)]
        )
        const position = level.productStacks.findIndex((stack) => stack === undefined || stack === null);

        if (action.position === Side.RIGHT) {
          level.productStacks.splice(position + 1, 0, newStack);
        } else level.productStacks.splice(position, 0, newStack);
      }

    } else if (action.stackId) {
      // Add product on a new level on an existing stack.
      // If the new product does not have the same width as the product on which it is stacked,
      // the level will consist of multiple stacks, the undefined element is used to align the product on chosen side.
      const stack = getStackFromConfiguration(configuration, action.stackId);

      const newLevel: ProductLevel = new ProductLevel(uuid());
      if (action.position === undefined) {
        newLevel.product = newProduct
      } else {
        let newStack: ProductStack = new ProductStack(
          uuid(),
          [new ProductLevel(uuid(), newProduct)]
        )

        newLevel.productStacks = [newStack]

        if (action.position === Side.RIGHT) {
          newLevel.productStacks.unshift(undefined);
        } else newLevel.productStacks.push(undefined);
      }

      stack?.productLevels.unshift(newLevel);
    } else {
      // Add product on the ground level, a new stack is created and added to the configuration
      // at the beginning or the end based on which add button the user clicked.
      if (action.side === Side.LEFT) {
        configuration.productStacks.unshift(
          new ProductStack(uuid(), [
            new ProductLevel(uuid(), newProduct)
          ])
        )
      } else configuration.productStacks.push(
        new ProductStack(uuid(), [
          new ProductLevel(uuid(), newProduct)
        ])
      )
    }

    // If the new product is a hanging closet and the hanging height is lower than the products below the new product in the stack,
    // this hanging height will be set to the height of the products below
    if (newProduct.isHangingCloset) {
      let product;
      for (let i = 0; i < configuration.productStacks.length; i++) {
        const foundProduct: Product = getProductWithConfigurationIdFormStack(configuration.productStacks[i], newProduct.configurationId);
        if (foundProduct) {
          product = foundProduct;
          break;
        }
      }
      if (!product) return;
      product.hangingHeight = Math.max(product.hangingHeight, getTotalHeightBelowProductInConfiguration(configuration, newProduct.configurationId) + product.height);
    }

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: configuration
      })
    )
  }

  @Action(DeleteProductFromConfiguration)
  deleteProductFromConfiguration(ctx: StateContext<ConfiguratorStateModel>, action: DeleteProductFromConfiguration) {
    // Delete a product from the configuration, based on stack, level and product id, the product is deleted.
    // A recursive function is needed to find the stack
    const configuration: Configuration = JSON.parse(JSON.stringify(ctx.getState().configuration));
    const stack = getStackFromConfiguration(configuration, action.stackId);

    if (!stack) return;

    for (let i = 0; i < stack.productLevels.length; i++) {
      const level = stack.productLevels[i];
      if (level.id === action.levelId) {
        if (level.product?.id === action.productId) {
          stack.productLevels.splice(i, 1);
        }
      }
    }

    // Check if a stack still contains products, else the stack will be deleted
    let itemsToDelete: number[] = [];
    for (let i = 0; i < configuration.productStacks.length; i++) {
      if (stackIsEmpty(configuration.productStacks[i])) {
        itemsToDelete.push(i);
      }
    }

    itemsToDelete.forEach(i => {
      configuration.productStacks.splice(i, 1);
    })

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: configuration
      })
    );
  }

  @Action(SwitchProductStackPosition)
  switchProductPosition(ctx: StateContext<ConfiguratorStateModel>, action: SwitchProductStackPosition) {
    // Update the order of the stacks on the base layer
    const configuration: Configuration = JSON.parse(JSON.stringify(ctx.getState().configuration));
    const previousIndex: number = configuration.productStacks.findIndex((productStack) => productStack.id === action.productStackId)
    const productStack: ProductStack | undefined = configuration.productStacks.find((productStack) => productStack.id === action.productStackId);

    if (!productStack) return;

    // Based on the direction of the arrows that is clicked, the stack will be moved to the left or the right
    if (action.direction === Side.LEFT && previousIndex > 0) {
      configuration.productStacks[previousIndex] = configuration.productStacks[previousIndex - 1];
      configuration.productStacks[previousIndex - 1] = productStack
    } else if (action.direction === Side.RIGHT && previousIndex !== -1 && previousIndex < configuration.productStacks.length - 1) {
      configuration.productStacks[previousIndex] = configuration.productStacks[previousIndex + 1];
      configuration.productStacks[previousIndex + 1] = productStack
    }

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: configuration
      })
    )
  }

  @Action(UpdateMultiplyFactor)
  updateMultiplyFactor(ctx: StateContext<ConfiguratorStateModel>, action: UpdateMultiplyFactor) {
    // The multiply factor is calculated based on the viewport and the height and width of the stack.
    // This factor is used to keep the configuration on the screen.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        multiplyFactor: action.multiplyFactor
      })
    )
  }

  @Action(UpdateNumberOfAccessoriesInProduct)
  updateSelectedOfAccessory(ctx: StateContext<ConfiguratorStateModel>, action: UpdateNumberOfAccessoriesInProduct) {
    // A product can have multiple accessories, the updated accessory list is an argument of the action.
    // A recursive function is needed to find the required product.
    const configuration: Configuration = JSON.parse(JSON.stringify(ctx.getState().configuration));
    let product: Product;

    for (let i = 0; i < configuration.productStacks.length; i++) {
      const foundProduct: Product = getProductWithConfigurationIdFormStack(configuration.productStacks[i], action.productConfigurationId);
      if (foundProduct) {
        product = foundProduct;
        break;
      }
    }
    if (!product) return;
    product.accessories = action.accessories

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: configuration
      })
    )
  }

  @Action(UpdateFloorType)
  updateFloorType(ctx: StateContext<ConfiguratorStateModel>, action: UpdateFloorType) {
    // The floor type is visible in the configurator and is a part of the configurator object
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: patch<Configuration>({
          floorType: action.floorType
        })
      })
    )
  }

  @Action(UpdateFloorProperty)
  updateFloorProperty(ctx: StateContext<ConfiguratorStateModel>, action: UpdateFloorProperty) {
    // The floor property is visible in the configurator and is a part of the configurator object
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: patch<Configuration>({
          selectedFloorProperty: action.floorProperty
        })
      })
    )
  }

  @Action(UpdateWallProperty)
  updateWallProperty(ctx: StateContext<ConfiguratorStateModel>, action: UpdateWallProperty) {
    // The wall property is visible in the configurator and is a part of the configurator object
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: patch<Configuration>({
          selectedWallProperty: action.wallProperty
        })
      })
    )
  }

  @Action(UpdateRoomDimensions)
  updateRoomDimensions(ctx: StateContext<ConfiguratorStateModel>, action: UpdateRoomDimensions) {
    // The floor type is visible in the configurator and is a part of the configurator object
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: patch<Configuration>({
          roomDimensions: action.dimensions
        })
      })
    )
  }

  @Action(ResetConfiguration)
  resetConfiguration(ctx: StateContext<ConfiguratorStateModel>) {
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: new Configuration()
      })
    )
  }

  @Action(UpdateHangingHeightOfProduct)
  updateHangingHeightOfProduct(ctx: StateContext<ConfiguratorStateModel>, action: UpdateHangingHeightOfProduct) {
    // The hanging height of a hanging product can be updated, the new height is an argument of the action.
    // A recursive function is needed to find the required product.
    const configuration = JSON.parse(JSON.stringify(ctx.getState().configuration));
    let product: Product;

    for (let i = 0; i < configuration.productStacks.length; i++) {
      const foundProduct: Product = getProductWithConfigurationIdFormStack(configuration.productStacks[i], action.productConfigurationId);
      if (foundProduct) {
        product = foundProduct;
        break;
      }
    }
    if (!product) return;
    product.hangingHeight = action.height;

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: configuration
      })
    )
  }

  @Action(UpdateTotalHeight)
  updateTotalHeight(ctx: StateContext<ConfiguratorStateModel>, action: UpdateTotalHeight) {
    // Update total height of the configuration, this value is used when adding a new hanging closet
    ctx.setState(
      patch<ConfiguratorStateModel>({
        totalHeight: action.totalHeight
      })
    )
  }

  @Action(ChangeColorOfProducts)
  changeColorOfProducts(ctx: StateContext<ConfiguratorStateModel>, action: ChangeColorOfProducts) {
    // Update all products to the new color
    // A recursive function is used for this functionality
    const configuration: Configuration = JSON.parse(JSON.stringify(ctx.getState().configuration));

    for (let i = 0; i < configuration.productStacks.length; i++) {
      changeColorInStack(configuration.productStacks[i], action.color, action.products);
    }

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: configuration
      })
    )
  }

  @Action(DuplicateProductStack)
  duplicateProductStack(ctx: StateContext<ConfiguratorStateModel>, action: DuplicateProductStack) {
    // Duplicate an existing product stack and the duplicated product stack to the configuration
    const duplicatedProductStackIndex: number = ctx.getState().configuration.productStacks.findIndex(productStack => productStack.id === action.productStackId);
    const newProductStack: ProductStack = JSON.parse(JSON.stringify(ctx.getState().configuration.productStacks[duplicatedProductStackIndex]));
    newProductStack.id = uuid();

    if (duplicatedProductStackIndex < 0) return;

    // State is updated with the new updated configuration.
    ctx.setState(
      patch<ConfiguratorStateModel>({
        configuration: patch<Configuration>({
          productStacks: insertItem(newProductStack, duplicatedProductStackIndex)
        })
      })
    )
  }

  @Action(UpdateSelectedCategory)
  updateSelectedCategory(ctx: StateContext<ConfiguratorStateModel>, action: UpdateSelectedCategory) {
    ctx.setState(
      patch<ConfiguratorStateModel>({
        selectedCategory: action.category
      })
    )
  }

  @Action(FetchAllRoomProperties)
  fetchAllRoomProperties(ctx: StateContext<ConfiguratorStateModel>) {
    return this.configuratorService.fetchAllRoomProperties().pipe(
      tap((roomProperties: RoomProperty) => {
        ctx.setState(
          patch<ConfiguratorStateModel>({
            roomProperties: roomProperties
          })
        )
      })
    )
  }
}

function changeColorInStack(productStack: ProductStack, color: Color, products: Product[]) {
  // Recursive function to change the color of all products in the stack
  if (!productStack) return;

  for (let i = 0; i < productStack.productLevels.length; i++) {
    const newProduct = changeColorInLevel(productStack.productLevels[i], color, products);
    // If there is a product, the product with the old color is replaced with the new product
    if (newProduct) productStack.productLevels[i].product = newProduct;
  }
}

function changeColorInLevel(productLevel: ProductLevel, color: Color, products: Product[]) {
  if (productLevel.product) {
    if (productLevel.product.color.value !== color.value) {
      let newProduct: Product = products.find(product => product.id === productLevel.product.alternativeColor);

      if (!newProduct) return productLevel.product;
      newProduct = JSON.parse(JSON.stringify(newProduct));

      // Add the old configuration to the new product
      newProduct.configurationId = productLevel.product.configurationId;
      newProduct.accessories = productLevel.product.accessories
      newProduct.hangingHeight = productLevel.product.hangingHeight
      newProduct.selectedVariation = newProduct.variations?.find(variation => variation?.height === productLevel.product.selectedVariation?.height);

      newProduct.selectedAssemblySide = productLevel.product.selectedAssemblySide;
      if (newProduct.selectedVariation) newProduct.selectedImgUrl = productLevel.product.selectedAssemblySide === Side.LEFT ? newProduct.selectedVariation.imgUrl : newProduct.selectedVariation.alternativeImgUrl;
      else newProduct.selectedImgUrl = productLevel.product.selectedAssemblySide === Side.LEFT ? newProduct.imgUrl : newProduct.alternativeImgUrl
      return newProduct;
    }
  }

  if (!productLevel.productStacks || productLevel.productStacks.length < 1) return;
  for (let i = 0; i < productLevel.productStacks.length; i++) {
    changeColorInStack(productLevel.productStacks[i], color, products);
  }
}

function levelIsEmpty(productLevel: ProductLevel): boolean {
  // Recursive function to check if there are no more products in the level
  // Empty stacks in the level will be deleted
  if (productLevel.product) return false;
  if (!productLevel.productStacks || productLevel.productStacks.length < 1) return true;

  let itemsToDelete: number[] = [];
  for (let i = 0; i < productLevel.productStacks.length; i++) {
    if (stackIsEmpty(productLevel.productStacks[i])) {
      itemsToDelete.push(i);
    }
  }

  itemsToDelete.forEach(i => productLevel.productStacks?.splice(i, 1));
  return productLevel.productStacks.filter(stack => stack !== null).length < 1;
}

function stackIsEmpty(productStack: ProductStack | undefined): boolean {
  // Recursive function to check if there are no more products in the stack
  // Empty levels in the stack will be deleted
  if (!productStack) return false;
  if (productStack.productLevels.length < 1) return true;

  let itemsToDelete: number[] = [];
  for (let i = 0; i < productStack.productLevels.length; i++) {
    if (levelIsEmpty(productStack.productLevels[i])) {
      itemsToDelete.push(i);
    }
  }

  itemsToDelete.forEach(i => productStack.productLevels.splice(i, 1));
  return productStack.productLevels.length < 1;
}
