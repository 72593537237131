import {Product} from "../products/models/product";
import {Side} from "./models/side";
import {ProductVariation} from "../products/models/product-variation";
import {Accessory} from "../products/models/accessory";
import FloorType from "./models/floor-type";
import Color from "../products/models/colors";
import {Category} from "../products/models/category";
import {RoomDimensions} from "./models/room-dimensions";
import {FloorProperty} from "./models/floor-property";
import {WallProperty} from "./models/wall-property";

export class AddProductToConfiguration {
  static readonly type = '[Configuration] AddProduct';

  constructor(public product: Product, public stackHeightWithoutHangingProducts: number, public side: Side | undefined, public stackId?: string, public levelId?: string, public position?: Side, public productVariation?: ProductVariation, public hangingHeight?: number, public imgUrl?: string, public assembleSide?: Side) {
  }
}

export class DeleteProductFromConfiguration {
  static readonly type = '[Configuration] DeleteProduct'

  constructor(public stackId: string, public productId: number, public levelId: string) {
  }
}

export class SwitchProductStackPosition {
  static readonly type = '[Configuration] SwitchProductPosition'

  constructor(public direction: Side, public productStackId: string) {
  }
}

export class UpdateMultiplyFactor {
  static readonly type = '[MultiplyFactor] Update'

  constructor(public multiplyFactor: number) {
  }
}

export class UpdateNumberOfAccessoriesInProduct {
  static readonly type = '[Accessory] UpdateSelected'

  constructor(public productConfigurationId: string, public accessories: Accessory[]) {
  }
}

export class UpdateFloorType {
  static readonly type = '[Configuration] UpdateFloorType'

  constructor(public floorType: FloorType) {
  }
}

export class UpdateFloorProperty {
  static readonly type = '[Configuration] UpdateFloorProperty'

  constructor(public floorProperty: FloorProperty) {
  }
}

export class UpdateWallProperty {
  static readonly type = '[Configuration] UpdateWallProperty'

  constructor(public wallProperty: WallProperty) {
  }
}

export class UpdateRoomDimensions {
  static readonly type = '[Configuration] UpdateRoomDimensions'

  constructor(public dimensions: RoomDimensions) {
  }
}

export class UpdateTotalHeight {
  static readonly type = '[Configuration] UpdateTotalHeight'

  constructor(public totalHeight: number) {
  }
}


export class ResetConfiguration {
  static readonly type = '[Configuration] Reset'
}

export class UpdateHangingHeightOfProduct {
  static readonly type = '[Product] UpdateHangingHeight'

  constructor(public productConfigurationId: string, public height: number) {
  }
}

export class ChangeColorOfProducts {
  static readonly type = '[Color] ChangeColorOfProducts'

  constructor(public color: Color, public products: Product[]) {
  }
}

export class DuplicateProductStack {
  static readonly type = '[Configuration] DuplicateProductStack'

  constructor(public productStackId: string) {
  }
}

export class UpdateSelectedCategory {
  static readonly type = '[Category] UpdateSelectedCategory'

  constructor(public category: Category) {
  }
}

export class FetchAllRoomProperties {
  static readonly type = '[RoomProperty] FetchAllRoomProperties'
}
