<form (submit)="onOptionsSubmit()" [formGroup]="optionsForm">
  <div class="modal__header">
    <div class="modal__header-text">
      <h2 class="modal__title">
        {{ 'configurator.product-options-modal.title' | translate }}
      </h2>
    </div>
    <app-modal-close-button (onClose)="closeModal()"></app-modal-close-button>
  </div>

  <div class="modal__content">
    <div *ngIf="sideNeeded">
      <p class="option__info">
        {{ 'configurator.product-options-modal.line-side' | translate }}
      </p>
      <div class="option__container">
        <label [for]="'left-' + modalId"
               class="option__label option__label--card">
          <input
            [id]="'left-' + modalId"
            [value]="PositionSwitchDirections.LEFT"
            formControlName="side"
            name="side"
            type="radio">
          <div class="label__container">

            <div class="option__title__container">
              <span class="option__title">{{ 'configurator.product-options-modal.left' | translate }}</span>
              <span class="material-symbols-outlined option--selected">
                check_circle
              </span>
              <span class="material-symbols-outlined option--not-selected">
                radio_button_unchecked
              </span>
            </div>
          </div>
        </label>
        <label [for]="'right-' + modalId"
               class="option__label option__label--card">
          <input
            [id]="'right-' + modalId"
            [value]="PositionSwitchDirections.RIGHT"
            formControlName="side"
            name="side"
            type="radio">
          <div class="label__container">

            <div class="option__title__container">
              <span class="option__title">{{ 'configurator.product-options-modal.right' | translate }}</span>
              <span class="material-symbols-outlined option--selected">
                check_circle
              </span>
              <span class="material-symbols-outlined option--not-selected">
                radio_button_unchecked
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div *ngIf="product && product.variations && availableColorProperties?.length > 0">

      <p class="option__info">{{ 'configurator.product-options-modal.product-colors' | translate }}</p>
      <div class="option__colors">
        <label
          *ngFor="let colorProperty of availableColorProperties"
          class="configurator__color-chooser option__colors-chooser"
          [for]="colorProperty.id">
          <input formControlName="selectedColorProperty" [value]="colorProperty"
                 [id]="colorProperty.id"
                 type="radio">
          <div class="color__container">
            <div
              [style]="{'background': colorProperty.image ? 'url('+colorProperty.image+')' : colorProperty.hex}"></div>
            <div class="color__check">
            <span class="material-symbols-outlined">
              check
            </span>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div *ngIf="product && product.variations && availableProductHeights?.length > 0">
      <p class="option__info">{{ 'configurator.product-options-modal.product-height' | translate }}</p>
      <div class="option__colors">
        <div class="option__container">
          <label
            *ngFor="let availableHeight of availableProductHeights"
            [for]="'available-height-' + availableHeight"
            class="option__label option__label--card">
            <input
              [id]="'available-height-' + availableHeight"
              [value]="availableHeight"
              formControlName="selectedProductHeight"
              type="radio">
            <div class="label__container">

              <div class="option__title__container">
                <span class="option__title">{{ availableHeight }}cm</span>
                <span class="material-symbols-outlined option--selected">
                check_circle
              </span>
                <span class="material-symbols-outlined option--not-selected">
                radio_button_unchecked
              </span>
              </div>

              <div class="label__img-container">
                <img
                  class="h-full"
                  onerror="this.src='assets/images/fallback.png'"
                  [alt]="product.title + ' - ' + availableHeight + 'cm'"
                  [src]="getImageURLForAvailableHeight(availableHeight)">
              </div>
            </div>
          </label>
        </div>

      </div>
    </div>

    <div *ngIf="product && product.alternativeImgUrl">
      <p class="option__info">Welke monteermogelijkheid wil je?</p>

      <div class="option__container">
        <label [for]="'assembly-left-' + modalId"
               class="option__label option__label--card">
          <input
            [id]="'assembly-left-' + modalId"
            [value]="PositionSwitchDirections.LEFT"
            formControlName="assembly"
            name="assembly"
            type="radio">
          <div class="label__container">

            <div class="option__title__container">
              <span class="option__title">Links</span>
              <span class="material-symbols-outlined option--selected">
                check_circle
              </span>
              <span class="material-symbols-outlined option--not-selected">
                radio_button_unchecked
              </span>
            </div>

            <div class="label__img-container">
              <img
                class="h-full"
                onerror="this.src='assets/images/fallback.png'"
                [alt]="product.title + ' - montage links'"
                [src]="product.imgUrl">
            </div>
          </div>
        </label>
        <label [for]="'assembly-right-' + modalId"
               class="option__label option__label--card">
          <input
            [id]="'assembly-right-' + modalId"
            [value]="PositionSwitchDirections.RIGHT"
            formControlName="assembly"
            name="assembly"
            type="radio">
          <div class="label__container">

            <div class="option__title__container">
              <span class="option__title">Rechts</span>
              <span class="material-symbols-outlined option--selected">
                check_circle
              </span>
              <span class="material-symbols-outlined option--not-selected">
                radio_button_unchecked
              </span>
            </div>
            <div class="label__img-container">
              <img
                class="h-full"
                onerror="this.src='assets/images/fallback.png'"
                [alt]="product.title + ' - montage rechts'"
                [src]="product.alternativeImgUrl">
            </div>

          </div>
        </label>
      </div>
    </div>

    <div *ngIf="product?.isHangingCloset">
      <div class="option__info">
        <p>{{ 'configurator.product-options-modal.product-hanging-height' | translate }}</p>
        <p class="text--grey">{{ 'configurator.product-options-modal.product-hanging-height-info' | translate }}</p>
      </div>
      <div class="flex gap-2 flex-col items-center">
        <div class="w-full">
          <div class="flex gap-2 items-center">
            <input class="w-full" formControlName="height" id="height" placeholder="Hoogte" type="number"/>
            <p>cm</p>
          </div>
          <p
            *ngIf="(optionsForm.controls['height'].touched || optionsForm.controls['height'].dirty) && optionsForm.controls['height'].invalid"
            class="error-message">
            {{ 'configurator.product-options-modal.product-hanging-height-error' | translate:errorParams }}
          </p>
        </div>
        <p>{{ 'shared.translations.or' | translate }}</p>
        <button (click)="setHeightToTotalHeight()" class="button button--outline w-full" type="button">
          {{ 'configurator.product-options-modal.hang-top' | translate }}
        </button>
      </div>

    </div>
  </div>

  <div class="modal__actions">
    <button class="button button--primary w-full">
      {{ 'configurator.product-options-modal.confirm' | translate }}
    </button>
  </div>
</form>
