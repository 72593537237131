import {NgModule} from "@angular/core";
import {routing} from "./configurator.routes";
import {ConfiguratorComponent} from './components/configurator/configurator.component';
import {AsyncPipe, CurrencyPipe, DecimalPipe, NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {ConfiguratorProductComponent} from './components/configurator-product/configurator-product.component';
import {AddProductModalComponent} from './components/add-product-modal/add-product-modal.component';
import {SharedModule} from "../shared/shared.module";
import {ProductModule} from "../products/product.module";
import {
  ConfiguratorProductStackComponent
} from './components/configurator-product-stack/configurator-product-stack.component';
import {ProductListComponent} from './components/product-list/product-list.component';
import {ProductOptionsModalComponent} from './components/product-options-modal/product-options-modal.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StartScreenComponent} from './components/start-screen/start-screen.component';
import {EditRoomModalComponent} from './components/edit-room-modal/edit-room-modal.component';
import { ColorFilterComponent } from './components/color-filter/color-filter.component';
import { CategoryChooserComponent } from './components/category-chooser/category-chooser.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ConfiguratorComponent,
    ConfiguratorProductComponent,
    AddProductModalComponent,
    ConfiguratorProductStackComponent,
    ProductListComponent,
    ProductOptionsModalComponent,
    StartScreenComponent,
    EditRoomModalComponent,
    ColorFilterComponent,
    CategoryChooserComponent
  ],
    imports: [
        routing,
        NgForOf,
        NgIf,
        AsyncPipe,
        SharedModule,
        ProductModule,
        NgStyle,
        NgClass,
        CurrencyPipe,
        FormsModule,
        ReactiveFormsModule,
        DecimalPipe,
        TranslateModule,
    ]
})
export class ConfiguratorModule {
}
