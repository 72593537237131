import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../models/product";
import {Store} from "@ngxs/store";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;

  productVariationForm: FormGroup;

  constructor(private store: Store, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.productVariationForm = this.fb.group({
      selectedVariation: [this.product.selectedVariation ? this.product.selectedVariation : '']
    })
  }
}
