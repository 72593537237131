<form (submit)="saveAccessories()" [formGroup]="form" class="modal__content-wrapper">

  <div class="modal__header">
    <div class="modal__header-text">
      <h2 class="modal__title">
        <a (click)="$event.stopPropagation()" [href]="product.productUrl" class="flex mr-2" target="_blank">
          <span class="icon--small material-symbols-outlined text--grey">
            info
          </span>
        </a>

        <span class="text--primary">{{product.title}}</span>
        <span *ngIf="product.selectedVariation" class="text--grey text--normal">
          &nbsp;| {{product.selectedVariation.height}}cm
        </span>
      </h2>
      <p class="modal__subtitle">
        {{product.width}}&times;{{product.selectedVariation ? product.selectedVariation.height : product.height}}cm
        - {{product.color.title}}
      </p>
    </div>

    <app-modal-close-button (onClose)="closeModal()"></app-modal-close-button>
  </div>
  <ng-container *ngIf="product.accessories && product.accessories.length > 0; else noAccessories">

    <div class="modal__content">
      <p class="subtitle">Accessoires:</p>

      <search-bar
        #searchBarComponent
        (keydown.enter)="$event.preventDefault();"
        [(ngModel)]="searchKeyword"
        [ngModelOptions]="{standalone: true}"
        [placeholder]="'products.product-info-modal.search-placeholder' | translate">
      </search-bar>

      <ul class="list">
        <li *ngFor="let accessory of product.accessories | filterAccessories:searchKeyword" class="list__item">
          <app-accessory-card
            [accessory]="accessory"
            [form]="form"
            [product]="product">
          </app-accessory-card>
        </li>
      </ul>

    </div>

    <div class="modal__actions">
      <button class="button button--primary button--icon-text">
        <span class="material-symbols-outlined">
          save
        </span>
        {{ 'shared.translations.save' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #noAccessories>
    <app-empty-results class="h-full" [message]="'products.product-info-modal.empty-message' | translate"></app-empty-results>
  </ng-template>

</form>
