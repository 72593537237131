import {
  checkIfAllProductsHaveSameColorInProductLevel,
  getHeightFromLevel,
  getLevelFromLevel,
  getProductWithConfigurationIdFormLevel,
  getStackFromLevel,
  getTotalHeightBelowProductInProductLevel,
  getWidthForLevel,
  ProductLevel
} from "./product-level";
import {Product} from "../../products/models/product";
import {CalculateHeightBelowProduct, Configuration} from "./configuration";
import Color from "../../products/models/colors";

export class ProductStack {
  id: string;
  productLevels: ProductLevel[];

  constructor(id: string, productLevels?: ProductLevel[]) {
    this.id = id;
    if (!productLevels) this.productLevels = [];
    else this.productLevels = productLevels;
  }
}

// Recursive can't be placed in the class because after JSON.parse(JSON.stringify()) the methods aren't copied to the new object.

export function getStackFromStack(productStack: ProductStack, stackId: string): ProductStack | undefined {
  if (productStack.id === stackId) return productStack;
  if (productStack.productLevels.length < 1) return;
  for (let i = 0; i < productStack.productLevels.length; i++) {
    const level = productStack.productLevels[i]
    const stack = getStackFromLevel(level, stackId);
    if (stack) return stack;
  }
  return;
}

export function getLevelFromStack(productStack: ProductStack, levelId: string): ProductLevel | undefined {
  if (productStack.productLevels.length < 1) return;
  for (let i = 0; i < productStack.productLevels.length; i++) {
    const level = getLevelFromLevel(productStack.productLevels[i], levelId);
    if (level) return level;
  }
  return;
}

export function getProductWithConfigurationIdFormStack(productStack: ProductStack, configurationId: string): Product | undefined {
  if (productStack.productLevels.length < 1) return;
  for (let i = 0; i < productStack.productLevels.length; i++) {
    const product = getProductWithConfigurationIdFormLevel(productStack.productLevels[i], configurationId);
    if (product) return product;
  }
  return;
}

export function getHeightFromStack(productStack: ProductStack | undefined, configuration: Configuration): any {
  if (!productStack) return 0;

  let heightObj = {
    height: 0,
    heightForHangingProducts: 0,
  }
  for (let i = 0; i < productStack.productLevels.length; i++) {
    const levelHeight = getHeightFromLevel(productStack.productLevels[i], configuration)
    heightObj.height += levelHeight.height;
    heightObj.heightForHangingProducts += levelHeight.heightForHangingProducts;
  }

  return heightObj;
}

export function getWidthForStack(productStack: ProductStack | undefined) {
  if (!productStack) return 0;
  return getWidthForLevel(productStack.productLevels[productStack.productLevels.length - 1]);
}

export function getTotalHeightBelowProductInProductStack(productStack: ProductStack | undefined, productConfigurationId: string, calculateHeightBelowProduct: CalculateHeightBelowProduct): CalculateHeightBelowProduct {
  if (!productStack) return;

  for (let i = productStack.productLevels.length - 1; i >= 0; i--) {
    const productLevel = productStack.productLevels[i];
    const heightBelowProductInProductLevel = getTotalHeightBelowProductInProductLevel(productLevel, productConfigurationId, calculateHeightBelowProduct);
    if (heightBelowProductInProductLevel.productFound) return heightBelowProductInProductLevel;
  }

  return calculateHeightBelowProduct;
}

export function checkIfAllProductsHaveSameColorInProductStack(productStack: ProductStack, color: Color): boolean {
  if (!productStack) return;

  for (let i = 0; i < productStack.productLevels.length; i++) {
    const isSameColor = checkIfAllProductsHaveSameColorInProductLevel(productStack.productLevels[i], color)
    if (!isSameColor) return false;
  }

  return true;
}
