import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {checkIfAllProductsHaveSameColorInConfiguration, Configuration} from "../../models/configuration";
import {Select, Store} from "@ngxs/store";
import {Observable, Subscription} from "rxjs";
import {ModalService} from "../../../shared/modal/modal.service";
import {ConfiguratorState} from "../../configurator.state";
import {getHeightFromStack, ProductStack} from "../../models/product-stack";
import {convertRemToPixels} from "../../../shared/helpers/css.helpers";
import {ChangeColorOfProducts, UpdateMultiplyFactor, UpdateTotalHeight} from "../../configurator.actions";
import {Side} from "../../models/side";
import {TooltipPosition} from "../../../shared/tooltip/tooltip-position";
import Color from "../../../products/models/colors";
import {ProductState} from "../../../products/product.state";
import {Product} from "../../../products/models/product";
import {AddProductModalComponent} from "../add-product-modal/add-product-modal.component";
import {FetchAllColorProperties} from "../../../products/product.actions";
import {ProductProperty} from "../../models/product-property";
import {PopUp} from "../../../shared/pop-up/models/pop-up";
import PopUpType from "../../../shared/pop-up/models/pop-up-type";
import PopUpMessage from "../../../shared/pop-up/models/pop-up-message";
import {PopUpService} from "../../../shared/pop-up/pop-up.service";
import {ColorProperty} from "../../../products/models/color-property";


@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss']
})
export class ConfiguratorComponent implements OnInit, OnDestroy {
  @Select(ConfiguratorState.configuration) configuration$: Observable<Configuration>;
  @Select(ConfiguratorState.multiplyFactor) multiplyFactor$: Observable<number>
  @Select(ProductState.products) products$: Observable<Product[]>

  @Select(ProductState.productProperties) productProperties$: Observable<ProductProperty>
  productProperties: ProductProperty;
  fetchedProductProperties: boolean = false;
  productPropertiesRequestFailed: boolean = false;

  multiplyFactor: number = 2;
  products: Product[];

  @ViewChild("addProductModalComponentConfigurator") addProductModal: AddProductModalComponent;

  totalWidth: number;
  totalHeight: number;

  selectedSide: Side;
  selectedColor: ColorProperty;

  protected readonly Side = Side;
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly Color = Color;
  private subscriptions = new Subscription();
  addProductModalOpen: boolean = false;

  constructor(
    private store: Store,
    private modalService: ModalService,
    private popUpService: PopUpService,
  ) {
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateMultiplyFactor();
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onAddProduct(side?: Side) {
    this.selectedSide = side;

    this.modalService.open('configurator-addProduct');
    this.addProductModal.focusOnSearchbar()
  }

  openUpdateBackgroundModal() {
    this.modalService.open('update-background');
  }

  updateColor(color: string) {
    //this.store.dispatch(new ChangeColorOfProducts(color, this.products))
  }

  private fetchData(): void {
    this.subscriptions.add(this.configuration$.subscribe(
      (configuration) => {
        // Calculate total width and total height of the configuration,
        // a recursive function is used
        this.totalWidth = 0;
        this.totalHeight = 0;
        let totalHeightForHangingProducts = 0;

        if (configuration.productStacks.length > 0 && checkIfAllProductsHaveSameColorInConfiguration(configuration)) {
          this.selectedColor = configuration.productStacks[0].productLevels[configuration.productStacks[0].productLevels.length - 1].product.colorProperty;
          // TODO: show selectedColor
        } else {
          this.selectedColor = undefined;
        }

        for (let i = 0; i < configuration.productStacks.length; i++) {
          const productStack: ProductStack = configuration.productStacks[i];

          const bottomLevel = productStack.productLevels[productStack.productLevels.length - 1];
          if (bottomLevel.product) {
            this.totalWidth += bottomLevel.product.width
          }

          const stackHeight = getHeightFromStack(productStack, configuration);
          if (stackHeight.height > this.totalHeight) this.totalHeight = stackHeight.height;
          if (stackHeight.heightForHangingProducts > totalHeightForHangingProducts) totalHeightForHangingProducts = stackHeight.heightForHangingProducts;
        }

        this.calculateMultiplyFactor();
        this.store.dispatch(new UpdateTotalHeight(this.totalHeight));
      }
    ));

    this.subscriptions.add(this.multiplyFactor$.subscribe(factor => {
      this.multiplyFactor = factor;
    }))

    this.subscriptions.add(this.products$.subscribe(products => this.products = products))

    this.subscriptions.add(this.productProperties$.subscribe((productProperties) => {
      this.productProperties = productProperties;
      this.selectedColor = this.productProperties?.colors[0]
      if (!this.productProperties && !this.fetchedProductProperties) {
        this.fetchedProductProperties = true;
        this.subscriptions.add(this.store.dispatch(new FetchAllColorProperties()).subscribe({
          next: () => {
            this.productPropertiesRequestFailed = false;
          },
          error: () => {
            this.productPropertiesRequestFailed = true;
            this.popUpService.add(new PopUp(PopUpType.ERROR, PopUpMessage.LOADING_PRODUCT_PROPERTIES_PROBLEM.message, 'Woeps, iets is misgelopen...'))
          }
        }))
      }
    }))
    this.store.dispatch(new FetchAllColorProperties())
  }

  private calculateMultiplyFactor() {
    // Calculate multiply factor based on the total height and total width of the configuration,
    // the max value of the multiply factor is max 2
    let marginX: number = 24,
      marginY: number = 18,
      maxFactor: number = 2,
      minFactor: number = 1;

    if (window.innerWidth < 600) {
      marginX = 20;
      minFactor = 1.6;
    }

    let multiplyFactorHeight = (window.innerHeight - convertRemToPixels(marginY)) / this.totalHeight;
    let multiplyFactorWidth = (window.innerWidth - convertRemToPixels(marginX)) / this.totalWidth;

    if (multiplyFactorWidth < multiplyFactorHeight) this.store.dispatch(new UpdateMultiplyFactor(Math.max(Math.min(multiplyFactorWidth, maxFactor), minFactor)))
    else this.store.dispatch(new UpdateMultiplyFactor(Math.max(Math.min(multiplyFactorHeight, maxFactor), minFactor)))
  }
}
